import React from 'react';
import ExternalLink from 'Common/Links/ExternalLink';

const DATASET_DOCS_URL = 'https://docs.sesam.io/hub/documentation/building-blocks/datasets.html';

const DatasetInspectorViewerEmptyEntity = () => (
  <div className="dataset-inspector-viewer__empty-entity">
    <h1>
      Select a dataset entity from the list.
      <br />
      <ExternalLink target="_blank" rel="noopener noreferrer" href={DATASET_DOCS_URL}>
        Read more about datasets.
      </ExternalLink>
    </h1>
  </div>
);

export default DatasetInspectorViewerEmptyEntity;
